<template>
  <div v-if="initOk">
    <van-steps :active="active" active-color="#c12b35">
      <van-step>在线选宿</van-step>
      <van-step>在线缴费</van-step>
      <van-step>完成选宿</van-step>
    </van-steps>

    <div class="container">
      <van-divider
        v-if="hostelStu.id == undefined"
        :style="{
          borderColor: '#ee0a24',
          padding: '0 100px',
        }"
      >
        在线选宿
      </van-divider>
      <van-form v-if="hostelStu.id == undefined" @submit="onSubmit">
        <van-cell is-link @click="show = true">
          <template #title>
            <template v-if="chooseHostel">
              {{ chooseHostel.split("/")[1] }} /
              {{ chooseHostel.split("/")[2] }} /
              {{ chooseHostel.split("/")[3] }} /
              {{ chooseHostel.split("/")[4] }} /
              {{ chooseHostel.split("/")[5] }} /
              {{ chooseHostel.split("/")[6] }}
            </template>
            <template v-else> 请选择宿舍 </template>
          </template>
        </van-cell>
        <van-notice-bar
          v-if="chooseHostel"
          left-icon="info-o"
          :text="chooseHostel.split('/')[0]"
        />
        <van-popup v-model="show" round position="bottom">
          <van-cascader
            title="请选择宿舍"
            :options="options"
            @close="show = false"
            @finish="onFinish"
          />
        </van-popup>
        <div style="margin: 16px">
          <van-button round block type="danger" native-type="submit"
            >提交</van-button
          >
        </div>
        <div class="explain">
          <p><b>说明：</b></p>
          <p style="text-indent: 2em">
            不同配套设施的宿舍有不同的价格，可根据您的实际情况按需选择。如有疑问请联系我们。
          </p>
          <p style="text-indent: 2em">宿舍问题咨询电话：</p>
          <p style="text-indent: 2em">0771-6609616（2024级本科，专科）</p>
          <p style="text-indent: 2em">0771-4733937（专升本）</p>
          <p style="text-indent: 2em">咨询时间：8:00-21:00</p>

          <p v-for="(src, index) in hostelImgs" :key="index">
            <img style="width: 100%" :src="src" @click="showPreview(index)" />
          </p>
          <p style="text-align: center">图片仅供参考，以实际办理入住为准</p>
          <van-image-preview
            v-model="showHostelImg"
            :images="hostelImgs"
            :startPosition="startHostelImgPosition"
            @change="onHostelImgChange"
          >
            <template v-slot:index>第{{ hostelImgIndex + 1 }}页</template>
          </van-image-preview>
        </div>
      </van-form>

      <div v-else>
        <van-panel
          title="您的宿舍"
          :desc="
            hostelStu.area +
            '/' +
            hostelStu.building +
            '/' +
            hostelStu.buildingNo +
            '/' +
            hostelStu.floor +
            '/' +
            hostelStu.room +
            '/' +
            hostelStu.bedNo +
            '号床'
          "
        >
          <van-notice-bar
            v-if="hostelStu.fee > 0"
            left-icon="info-o"
            :text="hostelStu.hostel.bedNo + '人间，住宿费￥' + hostelStu.fee"
          />
          <van-notice-bar
            v-else
            left-icon="info-o"
            :text="hostelStu.hostel.bedNo + '人间'"
          />
        </van-panel>
        <div v-if="hostelStu.isPaid">
          <van-divider
            :style="{
              borderColor: '#ee0a24',
              padding: '0 100px',
            }"
          >
            签到二维码
          </van-divider>
          <div class="qr">
            <vue-qr
              :text="api.getCheckInUrl() + loginUser.id"
              :size="200"
            ></vue-qr>
          </div>
        </div>
        <div v-else>
          <van-divider
            :style="{
              borderColor: '#ee0a24',
              padding: '0 100px',
            }"
          >
            在线缴费
          </van-divider>
          <van-form @submit="onSubmit2">
            <van-panel title="" desc="支付住宿费（元/年）">
              <div class="fee"><span>¥</span>{{ amount.toFixed(2) }}</div>
            </van-panel>
            <div
              v-if="
                hostelStu.feeReq == 'NONE' || hostelStu.feeReq == 'REJECTED'
              "
            >
              <div v-if="url" style="margin-top: 10px">
                <van-divider
                  :style="{
                    borderColor: '#ee0a24',
                    padding: '0 100px',
                  }"
                >
                  请扫一扫支付
                </van-divider>
                <div class="qr">
                  <vue-qr :text="url" :size="200"></vue-qr>
                </div>
              </div>
              <div v-else style="margin-top: 10px">
                <van-radio-group v-model="paymentMethod">
                  <van-cell-group>
                    <van-cell
                      title="微信支付"
                      label="推荐使用微信支付"
                      clickable
                      @click="paymentMethod = 'WECHAT'"
                    >
                      <template #icon>
                        <van-icon name="wechat" class="wechat-icon" />
                      </template>
                      <template #right-icon>
                        <van-radio name="WECHAT" />
                      </template>
                    </van-cell>
                    <van-cell
                      title="微信扫码支付"
                      label="请您的亲友扫码支付"
                      clickable
                      @click="paymentMethod = 'WECHAT_CODE'"
                    >
                      <template #icon>
                        <van-icon name="qr" class="wechat-icon" />
                      </template>
                      <template #right-icon>
                        <van-radio name="WECHAT_CODE" />
                      </template>
                    </van-cell>
                  </van-cell-group>
                </van-radio-group>
                <div style="margin-top: 10px">
                  <van-notice-bar
                    left-icon="info-o"
                    :text="
                      hostelStu.endPayTime > 0
                        ? '请在30分钟内完成支付，若未支付，需重新选择宿舍。'
                        : '支付已超时，请等待系统重置选宿记录。'
                    "
                  >
                  </van-notice-bar>
                  <template v-if="hostelStu.endPayTime > 0">
                    <van-button
                      block
                      type="danger"
                      native-type="submit"
                      :disabled="hostelStu.endPayTime <= 0"
                      >马上支付
                    </van-button>
                    <div class="fee-req" @click="navToFeeReq()">
                      申请缓交住宿费？
                    </div>
                  </template>
                  <van-button
                    v-else
                    block
                    type="danger"
                    native-type="button"
                    @click="findHostel"
                    >刷新
                  </van-button>
                  <div class="time">
                    <van-count-down
                      :time="hostelStu.endPayTime"
                      format="支付倒计时：mm 分 ss 秒"
                      @finish="setEndPayTimeZero"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-else style="margin-top: 10px">
              <van-button
                round
                block
                type="default"
                native-type="button"
                @click="navToFeeReq()"
                >查看我的缓交住宿费申请</van-button
              >
            </div>
          </van-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast, ImagePreview } from "vant";
import { Notify } from "vant";
import * as api from "@/apis/api";
import vueQr from "vue-qr";
export default {
  components: {
    vueQr,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      active: 0,
      api,
      initOk: false,
      show: false,
      hostelStu: {
        id: undefined,
      },
      chooseHostel: "",
      options: [],
      amount: 0,
      paymentMethod: "WECHAT",
      payment: {},
      url: undefined,
      checkInterval: undefined,

      showHostelImg: false,
      hostelImgIndex: 0,
      startHostelImgPosition: 0,
      hostelImgs: [
        "//yxht.gxufl.com/upload/hostels/1.jpg",
        "//yxht.gxufl.com/upload/hostels/2.jpg",
        "//yxht.gxufl.com/upload/hostels/3.jpg",
        "//yxht.gxufl.com/upload/hostels/4.jpg",
        "//yxht.gxufl.com/upload/hostels/5.jpg",
        "//yxht.gxufl.com/upload/hostels/6.jpg",
      ],
    };
  },
  computed: {
    loginUser() {
      return this.$store.getters.user;
    },
  },
  created() {
    this.findHostel();
  },
  methods: {
    setEndPayTimeZero() {
      this.hostelStu.endPayTime = 0;
    },
    findHostel() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      api.hostel().then((res) => {
        if (res.id) {
          this.hostelStu = res;
          this.active = 1;
          if (res.isPaid) {
            this.active = 2;
          }
          this.amount = res.fee;
        } else {
          this.hostelStu = {
            id: undefined,
          };
          this.active = 0;
          this.amount = 0;
          this.findHostelTree();
          this.chooseHostel = "";
        }
        Toast.clear();
        this.initOk = true;
      });
    },
    findHostelTree() {
      api.hostelTree().then((res) => {
        this.options = res;
      });
    },
    onFinish({ selectedOptions }) {
      this.show = false;
      this.chooseHostel = selectedOptions
        .map((option) => option.text)
        .join("/");
    },
    onSubmit(values) {
      console.log(values);
      // api.insertHostel(values).then((res) => {
      api.insertHostel({ chooseHostel: this.chooseHostel }).then((res) => {
        if (res.state == "ok") {
          Toast.success("操作成功");
          this.findHostel();
          // this.$router.push({ path: "/checkin", query: {} });
        } else {
          Notify({ type: "danger", message: res.msg });
        }
      });
    },

    isWx() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    onBridgeReady(data) {
      let _this = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: data.appId,
          timeStamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: data.nonceStr, // 支付签名随机串，不长于 32
          package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: data.paySign, // 支付签名
        },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            _this.findHostel();
            // _this.$router.push({ path: "/checkin", query: {} });
          }
        }
      );
    },
    onSubmit2(values) {
      let _this = this;
      values.paymentMethod = _this.paymentMethod;
      values.openId = localStorage.getItem("openId");
      values.amount = this.amount;
      values.isHostel = true;
      api.feePay(values).then((res) => {
        if (res.state == "ok") {
          if (_this.paymentMethod == "WECHAT") {
            if (!this.isWx()) {
              Notify({
                type: "danger",
                message: "请在微信内使用，或换一种支付方式",
              });
              return;
            }

            if (typeof WeixinJSBridge == "undefined") {
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  this.onBridgeReady(res.msg),
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent(
                  "WeixinJSBridgeReady",
                  this.onBridgeReady(res.msg)
                );
                document.attachEvent(
                  "onWeixinJSBridgeReady",
                  this.onBridgeReady(res.msg)
                );
              }
            } else {
              this.onBridgeReady(res.msg);
            }
          } else {
            //微信扫码
            _this.payment = res.msg.payment;
            _this.url = res.msg.url;
          }
          let checkInterval = window.setInterval(() => {
            api.feeCheck({ sn: _this.payment.sn }).then((res) => {
              if (res.state == "ok") {
                _this.findHostel();
                window.clearInterval(checkInterval);
                // _this.$router.push({ path: "/checkin", query: {} });
              }
            });
          }, 1000);
          this.checkInterval = checkInterval;
        } else {
          Notify({ type: "danger", message: res.msg });
        }
      });
    },
    showPreview(index) {
      this.index = index;
      this.startHostelImgPosition = index;
      this.showHostelImg = true;
    },
    onHostelImgChange(index) {
      this.hostelImgIndex = index;
    },
    navToFeeReq() {
      this.$router.push({ path: "/checkin/hostelFeeReq", query: {} });
    },
  },
};
</script>
<style scoped>
.container {
  padding: 10px;
}
.info {
  padding: 10px 15px;
  font-size: 14px;
}
.qr {
  padding: 20px;
  background-color: #fff;
  text-align: center;
}
.fee {
  padding: 20px;
  font-size: 24px;
  color: #c12b35;
}
.fee span {
  font-size: 12px;
}
.wechat-icon {
  font-size: 40px;
  color: #1989fa;
  margin-right: 10px;
}
.explain {
  margin-top: 20px;
  font-size: 12px;
  background-color: #fff;
  padding: 10px 10px;
}
.explain p {
  margin: 0;
  padding: 0;
  line-height: 2em;
}
.explain .red {
  color: #c12b35;
}
.fee-req {
  margin-top: 10px;
  text-align: right;
  font-size: 12px;
}
.time {
  text-align: center;
  margin: 10px 0;
}
.time .van-count-down {
  font-size: 12px;
}
</style>
